import TenanciesCount from "~/graphql/Portfolio/TenanciesCount.gql";
import TenancyFilterBookmarks from "~/graphql/Portfolio/TenancyFilterBookmarks.gql";

import { TenancyDecomissionedFilter, TenancySoldFilter, excludeTenancyFromResultsFilter } from "./apollo-portfolio-filter-helpers";
import { InputBuilder } from "./apollo-portfolio-helpers.js";

export const getTenancyCountQuery = function () {
  return {
    query: TenanciesCount,
    variables() {
      return new InputBuilder()
      .setFilter(excludeTenancyFromResultsFilter(), "tenancy")
      .setFilter(new TenancyDecomissionedFilter(), "tenancy").setFilter(new TenancySoldFilter(), "tenancy").build();
    },
    update: (data) => {
      return data.assetManagementTenancies.metadata.totalCount;
    },
  };
};

export const getDefaultTenancyFilterBookmarkQuery = function () {
  return {
    query: TenancyFilterBookmarks,
    update(data) {
      return data.assetManagementTenancyFilterBookmarks.find((fm) => fm.isDefault);
    },
  };
};
