
export default {
  components: {},

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Object, Boolean],
      default: () => null,
    },

    colorName: {
      type: String,
      required: true,
    },

    minWidth: {
      type: String,
      default: "0",
    },

    maxWidth: {
      type: String,
      default: "100%",
    },
  },

  computed: {
    _value: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit("input", value);
      },
    },

    colorBackground() {
      if (this.colorName === "white") {
        return "--color-black-50";
      }

      return `--color-${this.colorName}-100`;
    },

    colorIcon() {
      if (this.colorName === "white") {
        return "--color-black-700";
      }

      return `--color-${this.colorName}-900`;
    },
  },
};
