
import moment from "moment";

import PortfolioPlannedRent from "~/graphql/Portfolio/PortfolioPlannedRent.gql";
import UpdatePortfolio from "~/graphql/Portfolio/Mutations/UpdatePortfolio.gql";

import ColorDropDown from "../../ColorDropDown/ColorDropDown.vue";

import { filter } from "~/composables/useFilter";
import { getColorFromHex } from "~/helpers/color-helpers.ts";
import { formatRentRate } from "~/helpers/portfolio/portfolio-graph-helpers";
import { GraphInputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers.js";
import { getNumberOfProperties, getNumberOfUnits, getPortfolioColors, getHexFromColor } from "~/helpers/portfolio/portfolio-helpers.js";

export default {
  components: { ColorDropDown },

  inheritAttrs: false,

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  apollo: {
    rent: {
      query: PortfolioPlannedRent,
      variables: function () {
        return new GraphInputBuilder()
          .setPortfolioId(this.portfolio.id)
          .setDateFrom(moment().subtract(1, "year").utc().format("YYYY-MM-DD"))
          .setDateTo(moment().utc().format("YYYY-MM-DD"))
          .setPeriodType("MONTHLY")
          .setCategory("RENT")
          .build();
      },
      update(data) {
        return data.plannedRentsMetricsForPortfolio;
      },
    },
  },

  data() {
    return {
      colors: getPortfolioColors(),
    };
  },

  computed: {
    rentLoading() {
      return this.$apollo.queries.rent.loading;
    },

    color() {
      return getColorFromHex(this.portfolio.color);
    },

    filter() {
      return filter;
    },

    colorWidgetBackground() {
      if (this.color === "white") {
        return "--color-white";
      }

      return `--color-${this.color}-50`;
    },

    colorHeaderCopy() {
      if (this.color === "white") {
        return "--color-black-900";
      }

      return `--color-${this.color}-900`;
    },

    colorButtonCopy() {
      if (this.color === "white") {
        return "--color-black-900";
      }

      return `--color-${this.color}-1000`;
    },

    colorChart() {
      if (this.color === "white") {
        return "--color-black-100";
      }

      return `--color-${this.color}-500`;
    },

    numberOfProperties() {
      return getNumberOfProperties(this.portfolio);
    },

    numberOfUnits() {
      return getNumberOfUnits(this.portfolio);
    },

    yearlyRent() {
      const rent = this.portfolio.latestPlannedRentMetric;

      return this.filter.currency(rent?.amount, { currency: rent?.currency });
    },

    rentRate() {
      if (!this.rent) return null;

      return formatRentRate(this.rent, "MONTHLY");
    },

    rentRateCurrency() {
      if (!this.rent) return null;

      return this.rent.find((r) => r.currency)?.currency;
    },
  },

  methods: {
    updateColor(color) {
      this.$apollo.mutate({
        mutation: UpdatePortfolio,
        variables: {
          input: {
            id: this.portfolio.id,
            color: getHexFromColor(color),
          },
        },
      });
    },

    navigateToPortfolio(id, page) {
      const properties = {
        hierarchy: "portfolio",
        page: page,
      };

      this.$amplitude.navigateToTab(properties);

      this.$router.push({
        name: `portfolio-portfolioId-${page}`,
        params: {
          portfolioId: id,
        },
      });
    },

    navigateToWarnings() {
      this.$router.push({
        name: "portfolio-units",
      });
    },
  },
};
